<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    
  >
  
    <template #button-content>
      <feather-icon
        :badge="read"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{read}} New
        </b-badge>
      </div>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      ref="scrollbar"
    >
      <!-- Account Notification -->

      <b-link
        v-for="notification in notificationss"
        :key="notification._id"
        @click="isRead(notification._id)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="48"
              :variant="notification.d_type"
              :badge="!notification.read"
              class="badge-minimal"
              badge-variant="info"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <!-- <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p> -->
          <p style="margin-bottom:0rem; color:#d0d2d6;">
            {{ notification.subtitle }}
          </p>
          <p style="margin-bottom:0rem" class="notification-text"><small>{{formatDateToMonthShort(notification.created_at)}}</small></p>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

import RepositoryFactory from '../../api/RepositoryFactory'
import router from '@/router'
const NotificationRepository = RepositoryFactory.get('notifications')

// const socketConnection = SocketIO('jbq22040@eoopy.com');
// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: socketConnection,
// }))

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      notifications: [],
      temp: null,
      read: 0,
      componentKey: 0,
    }
  },
  created(){
    NotificationRepository.fetch().then(rs=>{
      this.notifications = [...rs.data.data]
      this.read =  this.notifications.filter(x=>!x.read).length
    }).catch(e=>{

    })
  },
  // sockets: {
  //   connect(){
  //     if(this.$store.state.auth.token){
  //       this.$socket.emit('authenciation',{
  //         token: this.$store.state.auth.token
  //       })
  //     }
      
  //   },
  //   new_notification(message){
  //     this.notifications.push(message.value)
  //     this.read =  this.notifications.filter(x=>!x.read).length
  //     this.$toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: 'Notification',
  //           icon: 'BellIcon',
  //           text: message.value.subtitle,
  //           variant: message.value.d_type,
  //         },
  //       },{
  //         position: 'bottom-left'
  //       }
  //       );
  //   }
  // },
  computed:{
    notificationss(){
      return  this.notifications.sort((a,b)=>{
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      })
    }
  },
  methods: {
    formatDateTime(date){
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('en-US', options).format(date)
    },
    isRead(id){
      NotificationRepository.read(id)
      this.notifications.find(x=>x._id == id).read = true;
      this.read =  this.notifications.filter(x=>!x.read).length
      let a = (this.notifications.find(x=>x._id == id))

      if(a.url != "")
        router.push(this.notifications.find(x=>x._id == id).url).catch(err => {})
    },
    formatDateToMonthShort (value, toTimeForCurrentDay = true){
      function isToday(date){
        const today = new Date()
          return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
          )
      }
      const date = new Date(value)
      let formatting = { month: 'short', day: 'numeric' }

      if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
      }

      return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
    }
  }, 
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 160,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
